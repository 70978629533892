import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`A passionate Software Engineer who likes to work on a highly-scaled application used by millions of users, an application developed using cutting-edge technologies.`}</p>
    </blockquote>
    <p>{`I love the open-source community and usually explore projects on GitHub/GitLab as a hobby. I'm familiar with the modern Agile paradigm, and Product Management tools like Jira, ClickUp, Monday, GitHub Projects, etc. I have experience leading all kinds of Scrum ceremonies as an Agile Coach.`}</p>
    <p>{`I strongly follow the conventions of the technology that I work on so that my contribution is efficient & reusable.
I'm willing to work both remotely and in person as well.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      